import React from 'react'

export const AboutMock = () => {
  return (
    <div className="about-container">
      <p>
        Em 1924, o Sr. Leonardo Valente Hyczy deu início às atividades
        comerciais com um pequeno comércio de Armazém e Padaria com o nome de
        “Ponto Chique” na cidade de Guarapuava no estado do Paraná, principiando
        o que vem a ser hoje o Grupo Superpão.
      </p>
      <p>
        O Ponto Chique iniciou suas atividades com a fabricação de um biscoito
        especial, assado em forno a lenha, que logo tornou-se conhecido dentro e
        fora de Guarapuava e desta forma, o biscoito passou a ser distribuído
        para clientes de Curitiba e São Paulo.
      </p>
      <p>
        Em 1937 foi inaugurada a panificadora especializada na fabricação de
        pães, doces e salgados. Com a ampliação das atividades em 1969 é
        instalado o sistema de autosserviços conhecido por Triunfante.
      </p>
      <p>
        O desenvolvimento da cidade e o aumento da necessidade da população
        marcaram o surgimento do primeiro supermercado de Guarapuava, trazendo
        para os clientes as tendências dos grandes centros. Foi quando na década
        de 70 surgiu a bandeira que hoje leva o nome do Grupo, o Supermercado
        Superpão.
      </p>
      <p>
        A empresa foi se diversificando e hoje trabalhamos com 6 bandeiras e uma
        Padaria no Atacado e Varejo, sendo elas: Superpão, Superpão Compre Mais,
        Super Baratão, Superpão Atacado, Oba e Triunfante (Padaria e
        Confeitaria, localizada dentro de uma unidade Superpão em Guarapuava).
        Além dessas bandeiras, o Grupo Superpão atua em outros segmentos como
        Posto de Combustível, localizadas nos estados do Paraná e Santa
        Catarina, estando presentes em mais de 16 cidade e contando com mais de
        3.600 Colaboradores (dados de 2021).
      </p>
      <p>
        Tudo isso faz com que o Grupo esteja entre as MAIORES redes de
        supermercados e umas das que mais se desenvolvem no estado do Paraná.
      </p>
    </div>
  )
}
